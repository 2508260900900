<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between class="mt-2">
        <v-flex xs12 sm4 md4 lg4 v-if="!selected" class="pa-1">
          <v-select
            dense
            solo
            class="caption"
            :label="$t('select business')"
            item-text="businessName"
            item-value="id"
            :items="ownersBusiness"
            v-model="selectedShop"
            @change="onSelectShop"
          ></v-select>
        </v-flex>

        <v-flex xs12 sm4 md4 lg4 class="text-right pa-1">
          <v-text-field
            v-model="search"
            dense
            solo
            :placeholder="$t('search receipt')"
          ></v-text-field> </v-flex
        ><v-spacer></v-spacer>
        <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="receiptDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="receiptDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                in
                solo
              ></v-text-field>
            </template>
            <v-date-picker v-model="receiptDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(receiptDate);
                  dateFilter();
                "
              >
                {{ $t("ok") }}
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-flex>
      </v-layout>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="filteredReceipts"
            :items-per-page="20"
            :search="search"
            :loading="loading"
            :loading-text="$t('loading...')"
          >
            <template v-slot:[`item.businessId`]="{ item }">
              {{ getBusinessName(item.businessId) }}
            </template>

            <template v-slot:[`item.qty`]="{ item }">
              {{ item.products.length }}
            </template>
            <template v-slot:[`item.productName`]="{ item }">
              {{ getProductName(item.productId) }}
            </template>
            <template v-slot:[`item.dateTime`]="{ item }">
              {{ formatDate(item.dateTime) }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom color="blue lighten-3">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    left
                    v-bind="attrs"
                    v-on="on"
                    @click="viewReceipt(item.id)"
                    :color="$store.state.primaryColor"
                    >visibility</v-icon
                  >
                </template>
                <span>{{ $t("details") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { format } from "date-fns";
import db from "@/plugins/fb";

export default {
  data: () => ({
    receiptDate: format(new Date(), "yyyy-MM-dd"),

    modal: false,
    loading: true,
    search: "",

    selectedShop: [],
    receipts: [],
    products: [],

    filteredReceipts: [],
  }),
  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },
    ownersBusiness() {
      return this.$store.getters.getBusinesses;
    },
    // filteredReceipts() {
    //   return this.receipts.filter((val) => {
    //     return val.receiptNo.includes(this.search);
    //   });
    // },
    headers() {
      return [
        {
          text: this.$t("receipt number"),
          value: "receiptNo",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("total amount"),
          value: "totalAmount",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("quantity"),
          value: "qty",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("payment method"),
          value: "paymentMethod",
          class: "blue-grey darken-3 white--text font-weight-regular",
          align: "center",
        },
        {
          text: this.$t("transaction date"),
          value: "dateTime",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("business"),
          value: "businessId",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
      ];
    },
  },

  mounted() {
    this.getProducts();
  },

  methods: {
    getReceipts() {
      if (this.selected) {
        db.collection("receipts")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.receipts.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            //get todays sales
            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.receipts.filter(
                (item) =>
                  format(item.dateTime.toDate(), "yyyy-MM-dd") ==
                  this.$store.state.todaysDate
              );
              this.filteredReceipts = filtered;
            } else {
              this.filteredReceipts = this.receipts;
            }
            this.$store.commit("SET_TODAY_DATE", "");
            this.loading = false;
          });
      } else {
        db.collection("receipts")
          .where("businessId", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.receipts.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            //get todays receipts
            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.receipts.filter(
                (item) =>
                  format(item.dateTime.toDate(), "yyyy-MM-dd") ==
                  this.$store.state.todaysDate
              );
              this.filteredReceipts = filtered;
            } else {
              this.filteredReceipts = this.receipts;
            }
            this.$store.commit("SET_TODAY_DATE", "");
            this.loading = false;
          });
      }
    },

    onSelectShop() {
      this.receipts = [];
      db.collection("receipts")
        .where("businessId", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.receipts.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          this.filteredReceipts = this.receipts;
          this.loading = false;
        });
    },

    getProducts() {
      db.collection("products")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.products.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
      this.getReceipts();
    },

    getProductName(id) {
      let prodId = this.products.find((item) => item.id == id);
      return prodId.name;
    },
    getBusinessName(id) {
      let bizId = this.$store.getters.getBusinesses.find(
        (item) => item.id == id
      );
      return bizId.businessName;
    },

    dateFilter() {
      let filtered = this.receipts.filter(
        (item) =>
          format(item.dateTime.toDate(), "yyyy-MM-dd") == this.receiptDate
      );
      this.filteredReceipts = filtered;
    },

    viewReceipt(receiptNo) {
      this.$router.push({
        name: "ViewReceiptDetails",
        params: { id: receiptNo },
      });
    },

    formatDate(val) {
      var date = val.toDate();
      return format(date, "EEE, dd MMMM, yyyy");
    },
  },
};
</script>

<style></style>
